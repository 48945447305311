import { useEffect } from "react";

import { encode as base64_encode } from "base-64";
import { useParams } from "react-router-dom";
import { getLandingPage } from "services";

export function PartnerOffer() {
  const params = useParams();

  useEffect(() => {
    setup();
  }, []);

  const setup = async () => {
    const token = params["token"];
    if (!!token) {
      window.location.href = `${getLandingPage()}/?c=${encodeURIComponent(
        base64_encode(
          JSON.stringify({
            partnerOffer: token,
          })
        )
      )}`;
    } else {
      window.location.href = getLandingPage();
    }
  };

  return <></>;
}
