import { StepperIcon } from "./stepper-icon";

export function Stepper({ steps = [] }) {
  return (
    <>
      <div className="surface-db-stepper flex h-9 w-full flex-row items-center justify-between gap-3 self-stretch rounded-xl px-2 py-3">
        {steps.map((step, index) => (
          <StepperIcon key={index} variant={step} animate />
        ))}
      </div>
    </>
  );
}
