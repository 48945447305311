import { useEffect, useRef, useState } from "react";
import { useWindowSize } from "contexts";
import { Link } from "react-router-dom";

export function LogoSlider({
  title,
  orgs,
  className,
  xl_numPerSlide,
  lg_numPerSlide,
}) {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [numPerSlide, setNumPerSlide] = useState([]);
  const { wWidth } = useWindowSize();
  const intervalRef = useRef(null);

  const handleChangeSlide = (index) => {
    setCurrentSlide(index);
    resetInterval();
  };

  // Function to determine numPerSlide based on breakpoints
  const updateNumPerSlide = () => {
    if (wWidth >= 1024 && xl_numPerSlide) {
      setNumPerSlide(xl_numPerSlide);
    } else if (wWidth >= 768 && lg_numPerSlide) {
      setNumPerSlide(lg_numPerSlide);
    } else {
      setNumPerSlide(lg_numPerSlide);
    }
  };

  // Update numPerSlide on window resize
  useEffect(() => {
    updateNumPerSlide();
  }, [wWidth, xl_numPerSlide, lg_numPerSlide]);

  let start = 0;
  const chunks = numPerSlide.map((count) => {
    const chunk = orgs.slice(start, start + count);
    start += count;
    return chunk;
  });

  const resetInterval = () => {
    if (intervalRef.current) {
      clearInterval(intervalRef.current);
    }
    intervalRef.current = setInterval(() => {
      setCurrentSlide((prev) => (prev + 1) % chunks.length);
    }, 5000);
  };

  useEffect(() => {
    resetInterval();
    return () => clearInterval(intervalRef.current);
  }, [chunks.length]);

  return (
    <div
      className={`gap-md-c py:20 flex flex-col items-center justify-center self-stretch ${className}`}
    >
      <h2 className="h2 text-center lg:text-left">{title}</h2>

      <div className="flex gap-6 self-stretch">
        {chunks.map((chunk, i) => (
          <div
            className={`flex w-full flex-shrink-0 flex-col items-center justify-center gap-6 self-stretch py-2 transition-all duration-[2500ms] ease-in-out sm:flex-row ${
              currentSlide === i ? "opacity-100" : "opacity-0"
            }`}
            key={`Logo-Chunk-${i}`}
            style={{
              transform: `translateX(calc(${currentSlide * -100}% + ${
                currentSlide * -24
              }px))`,
            }}
          >
            {chunk?.map((org, j) =>
              org === "full-pad" ? (
                <div key={j} className="hidden flex-1 sm:block" />
              ) : org === "half-pad" ? (
                <div key={j} className="hidden flex-[0.5] sm:block" />
              ) : org?.link ? (
                <Link
                  key={org?.name}
                  className="flex-1"
                  rel="noopener noreferrer"
                  target="_blank"
                  to={org?.link}
                >
                  <div className="flex h-[165px] w-[235px] items-center justify-center rounded-2xl bg-gray-100 hover:bg-blueGradientHover p-1.5 shadow-md hover:scale-110 sm:w-full">
                    <div className="bg-gray-100 p-6 w-full h-full flex flex-col items-center justify-center rounded-lg">
                      <img
                        src={org?.logo}
                        alt={`${org?.name} Logo`}
                        className="lg:w-32"
                      />
                    </div>
                  </div>
                </Link>
              ) : (
                <div key={org?.name} className="flex-1">
                  <div className="flex h-[165px] w-[235px] items-center justify-center rounded-2xl bg-gray-100 hover:bg-blueGradientHover p-1.5 shadow-md hover:scale-110 sm:w-full">
                    <div className="bg-gray-100 p-6 w-full h-full flex flex-col items-center justify-center rounded-lg">
                      <img
                        src={org?.logo}
                        alt={`${org?.name} Logo`}
                        className="lg:w-32"
                      />
                    </div>
                  </div>
                </div>
              )
            )}
          </div>
        ))}
      </div>

      {chunks?.length > 1 && (
        <div className="flex items-center justify-center gap-4 self-stretch">
          {chunks?.map((_, i) => (
            <button
              key={`Btn-${i}`}
              className={`size-4 rounded-full enabled:hover:bg-gray-300 ${
                currentSlide === i ? "bg-gray-400" : "bg-gray-100"
              }`}
              disabled={currentSlide === i}
              onClick={() => handleChangeSlide(i)}
            />
          ))}
        </div>
      )}
    </div>
  );
}
