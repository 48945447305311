import { useEffect, useRef, useState } from "react";

import { HiCheck } from "react-icons/hi";
import {
  LEVEL_ANIMATIONS,
  LEVEL_BACKGROUND_COLOURS,
  LEVEL_BADGES,
  LEVEL_TEXT_COLOURS,
  LEVELS,
} from "variables";

export function LevelCard({
  details = [],
  level = 0,
  price = "",
  standard = "",
}) {
  const [isButtonVisible, setIsButtonVisible] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);

  const btnRef = useRef(null);
  const videoRef = useRef(null);

  const handlePlayStop = () => {
    if (videoRef.current) {
      if (isPlaying) {
        videoRef.current.pause();
        // videoRef.current.currentTime = 0;
      } else {
        videoRef.current.play();
      }
    }

    setIsPlaying(!isPlaying);
  };

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        setIsButtonVisible(entry.isIntersecting);
      },
      { threshold: 0.1 }
    );

    if (btnRef.current) {
      observer.observe(btnRef.current);
    }

    return () => {
      if (btnRef.current) {
        observer.unobserve(btnRef.current);
      }
    };
  }, []);

  return (
    <div
      className={`flex h-fit xl:h-125 flex-shrink-0 flex-col justify-between rounded-2xl p-5 xl:p-8 sm:flex-1 gap-6 ${LEVEL_BACKGROUND_COLOURS[level]}`}
    >
      <div className="flex flex-col gap-4 self-stretch">
        <div className="flex justify-between xl:flex-row lg2:flex-col gap-4">
          <div className="flex flex-col gap-1">
            <h3
              className={`h3 font-bold leading-tight ${LEVEL_TEXT_COLOURS[level]}
              ${LEVELS[level] === "Platinum" || LEVELS[level] === "Diamond" ? "flex w-full justify-between flex-row gap-2 items-center" : ""}`}
            >
              {LEVELS[level]}
              {LEVELS[level] === "Platinum" || LEVELS[level] === "Diamond" ? (
                <span
                  className={`text-xs font-medium text-uppercase px-2 py-1 rounded-lg text-white bg-gray-900 w-fit h-fit`}
                >
                  Verified
                </span>
              ) : (
                ""
              )}
            </h3>
            <span className="body-xs">
              {standard} - Level {level}
            </span>
          </div>

          <div className="flex flex-col items-end xl:items-end lg2:items-start">
            <h4
              className={`h4 font-bold leading-tight ${LEVEL_TEXT_COLOURS[level]}`}
            >
              ${price}
            </h4>
            <span className="body-xs">AUD plus GST</span>
          </div>
        </div>

        <div className="flex flex-col gap-1 lg2:gap-2">
          {details?.map((detail, index) => (
            <div
              key={index}
              className={`flex lg2:items-start xl:items-center gap-2 ${LEVEL_TEXT_COLOURS[level]}`}
            >
              <HiCheck className="size-4" />
              <span className="body-xs">{detail}</span>
            </div>
          ))}
        </div>
      </div>

      <img
        src={[LEVEL_BADGES[level]]}
        alt={`${LEVELS[level]} Badge`}
        className="object-contain h-36 xl:h-60"
      />

      {/* <div className="flex h-60 items-center justify-center px-4">
        <video
          ref={videoRef}
          src={LEVEL_ANIMATIONS[level]}
          loop
          muted
          playsInline
          poster={LEVEL_BADGES[level]}
          className="h-full"
        />
      </div> */}

      {/* <div className="flex items-center justify-end gap-2.5 self-stretch">
        <button
          className="text-gray overflow-hidden rounded-full enabled:hover:text-gray-500"
          disabled={!isButtonVisible}
          ref={btnRef}
          onClick={handlePlayStop}
        >
          {isPlaying ? (
            <BsPauseCircleFill className="size-8" />
          ) : (
            <BsPlayCircleFill className="size-8" />
          )}
        </button>
      </div> */}
    </div>
  );
}
