import { useEffect, useState } from "react";

import { decode as base64_decode } from "base-64";
import { PartnerLoginForm } from "components";
import { Alert } from "flowbite-react";
import { useLoadingState } from "middleware";
import queryString from "query-string";
import { HiCheckCircle } from "react-icons/hi";
import { useLocation } from "react-router-dom";
import { AuthService, clearUrlParamsState } from "services";

export function PartnerLogin() {
  const [infoMsg, setInfoMsg] = useState("");
  const [showResetSuccess, setShowResetSuccess] = useState(false);
  const [showClaimSuccess, setShowClaimSuccess] = useState(false);
  const [alertsTranslateY, setAlertsTranslateY] = useState();
  const { setLoading } = useLoadingState();

  const location = useLocation();

  /**
   * Ensure there is no logged in user session
   */
  useEffect(() => {
    (async () => {
      setLoading(true);
      await AuthService.ensureNoAuth();
      setLoading(false);
    })();
  }, []);

  const handleLogout = () => {
    setInfoMsg("You have successfully signed out!");
  };

  const handleRemoval = () => {
    setInfoMsg("Your user account has been removed successfully!");
  };

  const handleResetSucess = () => {
    setShowResetSuccess(true);
  };

  const handleClaimSuccess = () => {
    setShowClaimSuccess(true);
  };

  const handleDismissInfoAlert = () => {
    setInfoMsg("");
  };

  const handleDismissResetSucess = () => {
    setShowResetSuccess(false);
  };

  const handleDismissClaimSuccess = () => {
    setShowClaimSuccess(false);
  };

  useEffect(() => {
    const params = queryString.parse(location.search);

    if (!!params?.login && params.login === "Google") {
      AuthService.signinWithGoogle();
    }
    if (!!params?.c) {
      try {
        const cParams = JSON.parse(base64_decode(params?.c));
        if (cParams?.claimed_success === true) {
          handleClaimSuccess(true);
        } else if (cParams?.reset_success === true) {
          handleResetSucess(true);
        }
      } catch (error) {
        console.error(error);
      }
    }

    // Check if this was a recent logout
    if (!!params.logout) {
      handleLogout();
    }

    // Check if this was a recent account removal
    if (!!params.removal) {
      handleRemoval();
    }

    clearUrlParamsState();
  }, [location.search]);

  useEffect(() => {
    const updateAlertPos = () => {
      const mainContentHeight =
        document.querySelector(".main-content").clientHeight;
      const formContainerHeight =
        document.querySelector(".form-container").clientHeight;

      // Account for padding (160px), navbar height (75px) and extra space created in main content
      const translateY =
        (mainContentHeight - formContainerHeight) / 4 - (160 - 75) / 2;

      setAlertsTranslateY(translateY);
    };
    updateAlertPos();

    window.addEventListener("resize", updateAlertPos);

    return () => {
      window.removeEventListener("resize", updateAlertPos);
    };
  }, []);

  return (
    <>
      <div
        className="absolute left-0 right-0 top-0 z-10 flex-col gap-4"
        style={{ transform: `translateY(${alertsTranslateY}px)` }}
      >
        {!!infoMsg && (
          <Alert
            color="success"
            icon={HiCheckCircle}
            className="w-full -translate-y-1/2"
            onDismiss={handleDismissInfoAlert}
          >
            <span className="text-sm font-semibold">{infoMsg}</span>
          </Alert>
        )}

        {showResetSuccess && (
          <Alert
            color="success"
            icon={HiCheckCircle}
            className="w-full -translate-y-1/2"
            onDismiss={handleDismissResetSucess}
          >
            <span className="text-sm font-semibold">
              Your password has been changed successfully!
            </span>
          </Alert>
        )}

        {showClaimSuccess && (
          <Alert
            color="success"
            icon={HiCheckCircle}
            className="w-full -translate-y-1/2"
            onDismiss={handleDismissClaimSuccess}
          >
            <span className="text-sm font-semibold">
              Your email has been verified successfully!
            </span>
          </Alert>
        )}
      </div>

      <PartnerLoginForm />

      {/* <PopupModal
        dismissible={true}
        show={claimSuccess}
        size={"sm"}
        onClose={() => setClaimSuccess(false)}
      >
        <Modal.Header>Your email is verified.</Modal.Header>
        <Modal.Body>
          Your email has been verified successfully!
          <br />
          Access your account by logging in.
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="h-[45px] w-40"
            onClick={() => setClaimSuccess(false)}
          >
            Login <HiArrowNarrowRight className="h-5 w-5" />
          </Button>
        </Modal.Footer>
      </PopupModal>

      <PopupModal
        dismissible={true}
        show={resetSuccess}
        size={"md"}
        onClose={() => setResetSuccess(false)}
      >
        <Modal.Header>Password reset successful.</Modal.Header>
        <Modal.Body>
          Your password has been changed successfully!
          <br />
          Access your account by logging in.
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="h-[45px] w-40"
            onClick={() => setResetSuccess(false)}
          >
            Login <HiArrowNarrowRight className="h-5 w-5" />
          </Button>
        </Modal.Footer>
      </PopupModal> */}
    </>
  );
}
