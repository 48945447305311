import { useEffect, useRef, useState } from "react";

import { useWindowSize } from "contexts";

export function BenefitsSliderHero({ data }) {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [sliderWidth, setSliderWidth] = useState(0);

  const { wWidth } = useWindowSize();

  const sliderRef = useRef(null);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % (data.length + 1));
    }, 4000);

    return () => clearInterval(interval);
  }, [data.length]);

  useEffect(() => {
    if (currentIndex === data.length) {
      setTimeout(() => {
        setCurrentIndex(0);
      }, 1000);
    }
  }, [currentIndex, data.length]);

  useEffect(() => {
    const observer = new ResizeObserver((entries) => {
      for (let entry of entries) {
        setSliderWidth(entry.contentRect.width);
      }
    });

    if (sliderRef.current) {
      observer.observe(sliderRef.current);
    }

    return () => {
      if (sliderRef.current) {
        observer.unobserve(sliderRef.current);
      }
    };
  }, [sliderRef]);

  const FirstIcon = data[0].icon;
  const totalSize = data?.length * sliderWidth * 2;

  const isMobile = wWidth < 640;
  const fData = isMobile ? data : data.slice().reverse();

  return (
    <div
      className="flex h-16 flex-col sm:flex-row items-center gap-[100%] self-stretch overflow-hidden"
      ref={sliderRef}
    >
      {!isMobile && (
        <div
          className={`flex size-full flex-shrink-0 flex-row justify-center lg:justify-start items-center gap-4 duration-1000 ${currentIndex === 0 ? "transition-none" : "transition-transform"}`}
          style={{
            transform: isMobile
              ? `translateY(${currentIndex * -200}%)`
              : `translateX(calc(${-totalSize}px + ${currentIndex * 200}%))`,
            transitionTimingFunction: "cubic-bezier(0.3, 0, 0.3, 1)",
          }}
        >
          <FirstIcon className="size-7 lg:size-6 xl:size-10 flex-shrink-0 text-[#48A6CD]" />
          <h4 className="lg2:h4 text-lg text-white">{data[0].title}</h4>
        </div>
      )}

      {fData.map((item, index) => {
        const Icon = item.icon;

        return (
          <div
            key={index}
            className={`flex size-full flex-shrink-0 flex-row justify-center lg2:justify-start items-center gap-4 duration-1000 ${currentIndex === 0 ? "transition-none" : "transition-transform"}`}
            style={{
              transform: isMobile
                ? `translateY(${currentIndex * -200}%)`
                : `translateX(calc(${-totalSize}px + ${currentIndex * 200}%))`,
              transitionTimingFunction: "cubic-bezier(0.3, 0, 0.3, 1)",
            }}
          >
            <Icon className="size-7 lg2:size-6 xl:size-10 flex-shrink-0 text-[#48A6CD]" />
            <h4 className="text-lg lg2:h4 text-white">{item.title}</h4>
          </div>
        );
      })}

      {isMobile && (
        <div
          className={`flex size-full flex-shrink-0 flex-row justify-center lg:justify-start items-center gap-4 duration-1000 ${currentIndex === 0 ? "transition-none" : "transition-transform"}`}
          style={{
            transform: isMobile
              ? `translateY(${currentIndex * -200}%)`
              : `translateX(calc(${-totalSize}px + ${currentIndex * 200}%))`,
            transitionTimingFunction: "cubic-bezier(0.3, 0, 0.3, 1)",
          }}
        >
          <FirstIcon className="size-7 lg:size-6 xl:size-10 flex-shrink-0 text-[#48A6CD]" />
          <h4 className="lg2:h4 text-lg text-white">{data[0].title}</h4>
        </div>
      )}
    </div>
  );
}
