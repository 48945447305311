import App from "App";
import { Amplify } from "aws-amplify";
import React from "react";
import ReactDOM from "react-dom/client";
import { I18nextProvider } from "react-i18next"; // Add this line
import { BrowserRouter } from "react-router-dom";
import awsExports from "./aws-configure";
import i18n from "./locales/i18next.js"; // Adjust the path if necessary
import reportWebVitals from "./reportWebVitals";

import { isLocalHost, OFFICIAL_DOMAIN } from "services/index.js";
import "./index.css";

Amplify.configure({
  ...awsExports,
  Auth: {
    ...awsExports.Auth,
    // session across cybercert.ai domain
    cookieStorage: {
      domain: isLocalHost() ? document.location.hostname : OFFICIAL_DOMAIN,
      secure: !isLocalHost(),
      path: "/",
      expires: 30,
    },
  },
});

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <I18nextProvider i18n={i18n}>
        <App />
      </I18nextProvider>
    </BrowserRouter>
  </React.StrictMode>
);

reportWebVitals();
