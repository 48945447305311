import SixClicksLogoPng from "./6clicks.png";
import AssuredlyLogoPng from "./assuredly.png";
import CynchLogoPng from "./cynch.png";
import MyCISOLogoPng from "./my-ciso.png";
import CyberAwarePng from "./cyber-aware.png";

export const GRCSixClicksLogo = SixClicksLogoPng;
export const GRCAssuredlyLogo = AssuredlyLogoPng;
export const GRCCynchLogo = CynchLogoPng;
export const GRCMyCISOLogo = MyCISOLogoPng;
export const GRCCyberAwareLogo = CyberAwarePng;
