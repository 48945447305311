import { useEffect, useState } from "react";

import { FlowbiteSpinner } from "components";
import { useLocation, useNavigate } from "react-router-dom";
import { ROUTES } from "variables";

export function LandingWrapper({ children }) {
  const [loading, setLoading] = useState(true);

  const { search } = useLocation();

  const navigate = useNavigate();

  useEffect(() => {
    navigate(
      `/${ROUTES.SELF.PARTNERS_MSP.replace(":referralCode?", "")}${search || ""}`,
      {
        replace: true,
      }
    );
    setLoading(false);
  }, []);

  return <>{loading ? <FlowbiteSpinner /> : <>{children}</>}</>;
}
