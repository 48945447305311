import React, { useState } from "react";

import { SubmitButton } from "components";
import { useNavigate } from "react-router-dom";
import { delay } from "services";

export function PartnerFormCompletedStep() {
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);

  const handleFinish = async () => {
    setIsLoading(true);
    await delay(2000);
    navigate("/");
  };

  return (
    <div className="flex flex-grow flex-col justify-between gap-8 self-stretch">
      <span className="text-sm font-normal text-gray-900">
        Thank you for providing your details. Your CyberCert Partner Agreement
        will be emailed to you shortly for your sign off.
      </span>

      <div className="flex flex-col items-center gap-4 tablet:flex-row tablet:justify-end">
        <SubmitButton
          variant="small"
          isLoading={isLoading}
          onClick={handleFinish}
        >
          Finish
        </SubmitButton>
      </div>
    </div>
  );
}
