import { useEffect, useState } from "react";

import { SubmitButton } from "components";
import { Alert } from "flowbite-react";
import { BiSolidErrorAlt } from "react-icons/bi";

const DASHBOARD_OPTIONS = [
  {
    name: "MSPs",
    description:
      "For Managed Service Providers providing technical and cyber security support services to their clients.",
    route: "msp",
  },
  {
    name: "Consultants",
    description:
      "For consultants providing cyber security and cyber risk management support services to their clients.",
    route: "consultant",
  },
  {
    name: "Membership",
    description:
      "For organisations and associations providing membership services to member businesses.",
    route: "membership",
  },
  {
    name: "Franchise",
    description:
      "For Franchisors managing the cyber hygiene requirements of their Franchisee's businesses.",
    route: "franchise",
  },
  {
    name: "Vendors",
    description:
      "For vendors providing cyber security solutions to MSPs, consultants, and other channel partners.",
    route: "vendor",
  },
];

export function PartnerFormTypeStep({
  partnerState,
  setPartnerState,
  handleNextStep = () => {},
}) {
  const [isLoading, setIsLoading] = useState(false);
  const [err, setErr] = useState(null);

  const handleChange = (event) => {
    setPartnerState((prevState) => ({
      ...prevState,
      dashboardType: {
        ...prevState.dashboardType,
        value: event.target.value,
      },
    }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    // Trim values in state
    let submitState = { ...partnerState };
    for (const key in submitState) {
      if (submitState?.hasOwnProperty(key)) {
        submitState[key] = {
          ...submitState?.[key],
          value: submitState?.[key]?.value?.trim(),
        };
      }
    }

    // Check for invalid fields
    let isInvalidField = false;
    if (isInvalidField) {
      setErr({
        type: "non-selection",
        msg: "You have not selected a partner type.",
      });
      setPartnerState(submitState);
      return;
    }

    // Goto next step now
    setIsLoading(false);
    handleNextStep();
  };

  useEffect(() => {
    if (Object?.values(partnerState)?.every((obj) => !obj.error)) {
      setErr(null);
    }
  }, [partnerState]);

  return (
    <>
      {!!err?.msg && (
        <Alert
          color="failure"
          icon={BiSolidErrorAlt}
          onDismiss={() => setErr(null)}
        >
          {err.msg}
        </Alert>
      )}

      <form className="flex flex-col gap-8" onSubmit={handleSubmit}>
        <div className="flex flex-col gap-4">
          <span className="text-base font-medium text-black">Partner Type</span>
          <span className="text-sm font-normal text-gray-900">
            Select the option that best describes your business.
          </span>
        </div>

        <div className="flex flex-col gap-4">
          {DASHBOARD_OPTIONS?.map((option, index) => (
            <div key={index} className="flex gap-4">
              <input
                checked={option?.route === partnerState?.dashboardType?.value}
                disabled={isLoading}
                required
                name="dashboardType"
                type="radio"
                value={option?.name}
                onChange={() => {
                  handleChange({ target: { value: option?.route } });
                }}
              />

              <div className="flex flex-col gap-1">
                <span className="text-sm font-medium leading-none text-gray-600">
                  {option?.name}
                </span>
                <span className="text-xs font-normal text-gray-500">
                  {option?.description}
                </span>
              </div>
            </div>
          ))}
        </div>

        <div className="flex flex-col items-center gap-4 tablet:flex-row tablet:justify-end">
          <SubmitButton variant="small" type="submit" isLoading={isLoading}>
            Continue
          </SubmitButton>
        </div>
      </form>
    </>
  );
}
