import { useEffect, useState } from "react";

import {
  BackButton,
  CheckboxInput,
  FlowbiteTextInput,
  Select,
  SubmitButton,
} from "components";
import { Alert, Label } from "flowbite-react";
import { BiSolidErrorAlt } from "react-icons/bi";
import {
  handleEnterKeyFormSubmission,
  handleKeyDownInput,
  validateEmpty,
  validatePostCode,
} from "services";

const COUNTRY_OPTIONS = ["Australia"];

const STATE_OPTIONS = [
  "Select State",
  "ACT",
  "NSW",
  "NT",
  "QLD",
  "SA",
  "TAS",
  "VIC",
  "WA",
];

export function PartnerFormAddressStep({
  partnerState,
  setPartnerState,
  handleInput = () => {},
  handleNextStep = () => {},
  handlePrevStep = () => {},
}) {
  const [isLoading, setIsLoading] = useState(false);
  const [err, setErr] = useState(null);
  const [headOfficeCountryValue, setHeadOfficeCountryValue] = useState(
    !!partnerState?.headOfficeCountry?.value
      ? COUNTRY_OPTIONS.indexOf(partnerState?.headOfficeCountry?.value)
      : 0,
  );
  const [headOfficeStateValue, setHeadOfficeStateValue] = useState(
    !!partnerState?.headOfficeState?.value
      ? STATE_OPTIONS.indexOf(partnerState?.headOfficeState?.value)
      : 0,
  );
  const [postalCountryValue, setPostalCountryValue] = useState(
    !!partnerState?.postalCountry?.value
      ? COUNTRY_OPTIONS.indexOf(partnerState?.postalCountry?.value)
      : 0,
  );
  const [postalStateValue, setPostalStateValue] = useState(
    !!partnerState?.postalState?.value
      ? STATE_OPTIONS.indexOf(partnerState?.postalState?.value)
      : 0,
  );

  const handleChecked = (event) => {
    setPartnerState((prevState) => ({
      ...prevState,
      [event?.target?.name]: event?.target?.checked,
    }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    // Trim values in state
    let submitState = { ...partnerState };
    for (const key in submitState) {
      if (submitState?.hasOwnProperty(key)) {
        submitState[key] = {
          ...submitState?.[key],
          value: submitState?.[key]?.value?.trim(),
        };
      }
    }

    // Check for invalid fields
    let isInvalidField = false;
    let isNotSelected = false;

    // TODO: Country checking once more than Australia is added
    if (!validateEmpty(submitState?.headOfficeStreetName?.value)) {
      submitState.headOfficeStreetName.error = true;
      isInvalidField = true;
    }
    if (!validateEmpty(submitState?.headOfficeSuburb?.value)) {
      submitState.headOfficeSuburb.error = true;
      isInvalidField = true;
    }
    if (partnerState?.headOfficeState?.value === STATE_OPTIONS[0]) {
      submitState.headOfficeState.error = true;
      isNotSelected = true;
    }
    if (
      !validateEmpty(submitState?.headOfficePostcode?.value) ||
      !validatePostCode(submitState?.headOfficePostcode?.value)
    ) {
      submitState.headOfficePostcode.error = true;
      isInvalidField = true;
    }

    if (!submitState?.sameAddresses) {
      // TODO: Country checking once more than Australia is added
      if (!validateEmpty(submitState?.postalStreetName?.value)) {
        submitState.postalStreetName.error = true;
        isInvalidField = true;
      }
      if (!validateEmpty(submitState?.postalSuburb?.value)) {
        submitState.postalSuburb.error = true;
        isInvalidField = true;
      }
      if (partnerState?.postalState?.value === STATE_OPTIONS[0]) {
        submitState.postalState.error = true;
        isNotSelected = true;
      }
      if (
        !validateEmpty(submitState?.postalPostcode?.value) ||
        !validatePostCode(submitState?.postalPostcode?.value)
      ) {
        submitState.postalPostcode.error = true;
        isInvalidField = true;
      }
    }

    if (isInvalidField) {
      setErr({
        type: "invalid",
        msg: "You have entered an invalid field. Please try again.",
      });
      setPartnerState(submitState);
      return;
    } else if (isNotSelected) {
      setErr({
        type: "non-selection",
        msg: "You have not selected an option.",
      });
      setPartnerState(submitState);
      return;
    }

    // Goto next step now
    setIsLoading(false);
    handleNextStep();
  };

  useEffect(() => {
    setPartnerState((prevState) => ({
      ...prevState,
      headOfficeCountry: {
        ...prevState.headOfficeCountry,
        value: COUNTRY_OPTIONS[headOfficeCountryValue],
        error: false,
      },
    }));
  }, [headOfficeCountryValue]);

  useEffect(() => {
    setPartnerState((prevState) => ({
      ...prevState,
      postalCountry: {
        ...prevState.postalCountry,
        value: COUNTRY_OPTIONS[postalCountryValue],
        error: false,
      },
    }));
  }, [postalCountryValue]);

  useEffect(() => {
    setPartnerState((prevState) => ({
      ...prevState,
      headOfficeState: {
        ...prevState.headOfficeState,
        value: STATE_OPTIONS[headOfficeStateValue],
        error: false,
      },
    }));
  }, [headOfficeStateValue]);

  useEffect(() => {
    setPartnerState((prevState) => ({
      ...prevState,
      postalState: {
        ...prevState.postalState,
        value: STATE_OPTIONS[postalStateValue],
        error: false,
      },
    }));
  }, [postalStateValue]);

  useEffect(() => {
    if (Object?.values(partnerState)?.every((obj) => !obj.error)) {
      setErr(null);
    }
  }, [partnerState]);

  return (
    <>
      {!!err?.msg && (
        <Alert
          color="red"
          icon={BiSolidErrorAlt}
          onDismiss={() => setErr(null)}
        >
          {err?.msg}
        </Alert>
      )}

      <form className="flex flex-col gap-8" onSubmit={handleSubmit}>
        <div className="flex flex-col gap-4">
          <div className="flex flex-col gap-2">
            <span className="text-sm font-medium text-gray-600">
              Head Office Address
            </span>

            <Select
              disabled={isLoading}
              options={COUNTRY_OPTIONS}
              value={headOfficeCountryValue}
              setValue={setHeadOfficeCountryValue}
            />

            <div className="flex gap-2">
              <FlowbiteTextInput
                autocomplete="off"
                className="flex-1"
                color={
                  partnerState?.headOfficeStreetName?.error ? "failure" : "gray"
                }
                disabled={isLoading}
                name="headOfficeStreetName"
                placeholder="Street Address"
                required
                type="text"
                value={partnerState?.headOfficeStreetName?.value}
                onKeyDown={(event) =>
                  handleEnterKeyFormSubmission(event, () => handleSubmit(event))
                }
                onInput={handleInput}
              />

              <FlowbiteTextInput
                autocomplete="off"
                className="flex-1"
                color={
                  partnerState?.headOfficeSuburb?.error ? "failure" : "gray"
                }
                disabled={isLoading}
                name="headOfficeSuburb"
                placeholder="Suburb"
                required
                type="text"
                value={partnerState?.headOfficeSuburb?.value}
                onKeyDown={(event) =>
                  handleEnterKeyFormSubmission(event, () => handleSubmit(event))
                }
                onInput={handleInput}
              />
            </div>

            <div className="flex gap-2">
              <Select
                className="flex-1 text-sm"
                disabled={isLoading}
                error={partnerState?.headOfficeState?.error}
                options={STATE_OPTIONS}
                value={headOfficeStateValue}
                setValue={setHeadOfficeStateValue}
              />

              <FlowbiteTextInput
                autocomplete="off"
                className="flex-1"
                color={
                  partnerState?.headOfficePostcode?.error ? "failure" : "gray"
                }
                disabled={isLoading}
                name="headOfficePostcode"
                placeholder="Postcode"
                required
                type="tel"
                value={partnerState?.headOfficePostcode?.value}
                onKeyDown={(event) =>
                  handleEnterKeyFormSubmission(event, () => handleSubmit(event))
                }
                onInput={handleInput}
              />
            </div>
          </div>
          <div className="flex flex-col gap-2">
            <div className="flex justify-between">
              <span className="text-sm font-medium text-gray-600">
                Postal Address
              </span>

              <div className="flex flex-row items-center gap-2 whitespace-nowrap">
                <CheckboxInput
                  checked={partnerState.sameAddresses}
                  id="sameAddresses"
                  name="sameAddresses"
                  onChange={handleChecked}
                  onKeyDown={(event) => handleKeyDownInput(event)}
                />
                <Label
                  htmlFor="sameAddresses"
                  className="text-sm font-normal leading-none text-gray-600"
                >
                  Same as Above
                </Label>
              </div>
            </div>

            {!partnerState?.sameAddresses && (
              <>
                <Select
                  disabled={isLoading}
                  options={COUNTRY_OPTIONS}
                  value={postalCountryValue}
                  setValue={setPostalCountryValue}
                  required
                />

                <div className="flex gap-2">
                  <FlowbiteTextInput
                    autocomplete="off"
                    className="flex-1"
                    color={
                      partnerState?.postalStreetName?.error ? "failure" : "gray"
                    }
                    disabled={isLoading}
                    name="postalStreetName"
                    placeholder="Street Address"
                    required
                    type="text"
                    value={partnerState?.postalStreetName?.value}
                    onKeyDown={(event) =>
                      handleEnterKeyFormSubmission(event, () =>
                        handleSubmit(event),
                      )
                    }
                    onInput={handleInput}
                  />

                  <FlowbiteTextInput
                    autocomplete="off"
                    className="flex-1"
                    color={
                      partnerState?.postalSuburb?.error ? "failure" : "gray"
                    }
                    disabled={isLoading}
                    name="postalSuburb"
                    placeholder="Suburb"
                    required
                    type="text"
                    value={partnerState?.postalSuburb?.value}
                    onKeyDown={(event) =>
                      handleEnterKeyFormSubmission(event, () =>
                        handleSubmit(event),
                      )
                    }
                    onInput={handleInput}
                  />
                </div>

                <div className="flex gap-2">
                  <Select
                    className="flex-1 text-sm"
                    disabled={isLoading}
                    error={partnerState?.postalState?.error}
                    options={STATE_OPTIONS}
                    value={postalStateValue}
                    setValue={setPostalStateValue}
                  />

                  <FlowbiteTextInput
                    autocomplete="off"
                    className="flex-1"
                    color={
                      partnerState?.postalPostcode?.error ? "failure" : "gray"
                    }
                    disabled={isLoading}
                    name="postalPostcode"
                    placeholder="Postcode"
                    required
                    type="tel"
                    value={partnerState?.postalPostcode?.value}
                    onKeyDown={(event) =>
                      handleEnterKeyFormSubmission(event, () =>
                        handleSubmit(event),
                      )
                    }
                    onInput={handleInput}
                  />
                </div>
              </>
            )}
          </div>
        </div>

        <div className="flex flex-col items-center gap-4 tablet:flex-row tablet:justify-end">
          <BackButton isLoading={isLoading} onClick={handlePrevStep}>
            Back
          </BackButton>

          <SubmitButton variant="small" type="submit" isLoading={isLoading}>
            Continue
          </SubmitButton>
        </div>
      </form>
    </>
  );
}
