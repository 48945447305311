export const DashboardOptions = [
  {
    route: "supply-chain",
    title: "Supply Chain",
    orgTitle: "Suppliers",
    orgTitleSingle: "Supplier",
    orgSingle: "supplier",
    orgPlural: "suppliers",
    check: "isTypeSupplyChain",
    access: "supplyChainCustomers",
  },
  {
    route: "membership",
    title: "Membership",
    orgTitle: "Members",
    orgTitleSingle: "Member",
    orgSingle: "member",
    orgPlural: "members",
    check: "isTypeMembership",
    access: "membershipCustomers",
  },
  {
    route: "msp",
    title: "MSPs",
    orgTitle: "Clients",
    orgTitleSingle: "Client",
    orgSingle: "client",
    orgPlural: "clients",
    check: "isTypeMSP",
    access: "MSPCustomers",
  },
  {
    route: "consultant",
    title: "Consultant",
    orgTitle: "Clients",
    orgTitleSingle: "Client",
    orgSingle: "client",
    orgPlural: "clients",
    check: "isTypeConsultant",
    access: "consultantCustomers",
  },
  {
    route: "vendor",
    title: "Vendor",
    orgTitle: "Partners",
    orgTitleSingle: "Partner",
    orgSingle: "partner",
    orgPlural: "partners",
    check: "isTypeVendor",
    access: "vendorCustomers",
  },
  {
    route: "franchise",
    title: "Franchise",
    orgTitle: "Franchises",
    orgTitleSingle: "Franchise",
    orgSingle: "franchise",
    orgPlural: "franchises",
    check: "isTypeFranchise",
    access: "franchiseCustomers",
  },
  {
    route: "referrer",
    title: "Referrer",
    orgTitle: "Referrals",
    orgTitleSingle: "Referral",
    orgSingle: "referral",
    orgPlural: "referrals",
    check: "isTypeReferrer",
    access: "referrerCustomers",
  },
];
