import "./partnerStyles.css";

import { useEffect, useRef, useState } from "react";

import { verifyPartnerAccount } from "api";
import {
  ClientApproved,
  ClientsNeeds,
  CSCAUACSLogo,
  CSCAUAdhaLogo,
  CSCAUAisaLogo,
  CSCAUAsdLogo,
  CSCAUAusCertLogo,
  CSCAUBdoLogo,
  CSCAUCosboaLogo,
  CSCAUCybercxLogo,
  CSCAUDeloitteLogo,
  CSCAUGovsaLogo,
  CSCAUInsurancecouncilLogo,
  CSCAUMcGrathNicolLogo,
  CSCAUPacsonLogo,
  CSCAUSekuroLogo,
  CSCAUWillyamaCyberLogo,
  GRCAssuredlyLogo,
  GRCCyberAwareLogo,
  GRCCynchLogo,
  GRCMyCISOLogo,
  GRCSixClicksLogo,
  MSPWithClient,
  PAditsLogo,
  PApolloLogo,
  PBitsLogo,
  PBizGuardLogo,
  PBizItLogo,
  PCatalyticItLogo,
  PConcordItLogo,
  PCorCyberLogo,
  PCyberGuruLogo,
  PEResourcesLogo,
  PGeeks2uLogo,
  PHolocronCyberLogo,
  PIsparexLogo,
  PlatFormDashboardCTAImg,
  PlatFormDashboardCutImg,
  PlatformDashboardSlide1,
  PlatformDashboardSlide2,
  PlatformDashboardSlide3,
  PlatformDashboardSlide4,
  PlatformDashboardSlide5,
  PlatformDashboardSlide6,
  PlatformDashboardSlide7,
  PMercuryItLogo,
  PMustardSeedItLogo,
  PReddLogo,
  PSekuroLogo,
  PSentrianLogo,
  PSeqItServicesLogo,
  PTarianCyberLogo,
  PTechPathLogo,
  ShieldBg,
  SMB1001Img,
  TCatalyticITLogo,
  THuntressLogo,
  TSEQITServicesLogo,
  TShadowITLogo,
} from "assets";
import {
  BenefitCard,
  BenefitsSliderHero,
  ButtonWithRef,
  GetStartedCard,
  LevelCard,
  LogoSlider,
  TestimonialSlider,
} from "components";
import { useErrorBanner } from "contexts";
import {
  AiOutlineDollarCircle,
  AiOutlineVerticalAlignMiddle,
} from "react-icons/ai";
import { BiTrendingUp } from "react-icons/bi";
import {
  BsArrowLeftCircleFill,
  BsArrowRightCircleFill,
  BsFillBookmarksFill,
  BsUiChecksGrid,
} from "react-icons/bs";
import { CgScreen } from "react-icons/cg";
import { GiCheckedShield } from "react-icons/gi";
import { HiViewGridAdd } from "react-icons/hi";
import { HiChatBubbleLeft } from "react-icons/hi2";
import {
  RiArrowRightUpLine,
  RiBankFill,
  RiCheckboxMultipleLine,
  RiExchangeDollarLine,
  RiPriceTag3Line,
  RiShieldUserLine,
} from "react-icons/ri";
import { TbShieldCog, TbShieldSearch } from "react-icons/tb";
import { VscOpenPreview } from "react-icons/vsc";
import { Link, useNavigate, useParams } from "react-router-dom";
import { chunk, downloadCAP, scrollToElementSmoothly } from "services";
import {
  LEVEL_ALL_ANIMATED,
  LEVEL_DETAILS,
  LEVEL_PRICES,
  LEVELS,
  ROUTES,
} from "variables";

export function PartnersMspPage() {
  const [benefitsWidth, setBenefitsWidth] = useState(0);
  const [currentBenefitSlide, setCurrentBenefitSlide] = useState(0);
  const [referral, setReferral] = useState(null);

  const { setError } = useErrorBanner();
  const { referralCode } = useParams();

  const benefitsRef = useRef(null);

  const navigate = useNavigate();

  const handleNextBenefitSlide = () =>
    setCurrentBenefitSlide((prev) => prev + 1);
  const handlePrevBenefitSlide = () =>
    setCurrentBenefitSlide((prev) => prev - 1);

  const handleGetStarted = async (event, partnerState) => {
    event.preventDefault();
    navigate(`/${ROUTES.SELF.PARTNER_FORM}`, {
      state: { partnerState, referral, type: "msp" },
    });
  };

  const benefitsMaxSize =
    BENEFITS_DATA?.length * 256 + (BENEFITS_DATA?.length - 1) * 32;

  useEffect(() => {
    (async () => {
      if (!!referralCode) {
        let verify = await verifyPartnerAccount({
          referralCode,
        });

        if (verify?.referral) {
          setReferral(verify?.referral);
        } else {
          setError(
            "You have used an invalid referral code! Please check again."
          );
        }
      }
    })();
  }, []);

  useEffect(() => {
    const observer = new ResizeObserver((entries) => {
      for (let entry of entries) {
        setBenefitsWidth(entry.contentRect.width);
        setCurrentBenefitSlide((prev) =>
          Math.min(prev, Math.floor(benefitsMaxSize / entry.contentRect.width))
        );
      }
    });

    if (benefitsRef.current) {
      observer.observe(benefitsRef.current);
    }

    return () => {
      if (benefitsRef.current) {
        observer.unobserve(benefitsRef.current);
      }
    };
  }, [benefitsRef]);

  return (
    <>
      <section className="relative flex min-h-screen w-full flex-grow flex-col items-center justify-center bg-partner-hero lg2:bg-cover bg-right-bottom lg2:bg-top px-5 lg2:px-[7%] py-20 lg2:py-40">
        <div className="z-10 flex max-w-full xl:w-[1200px] mx-auto pt-12 lg2:pt-0">
          <div className="gap-md-c flex w-full flex-col items-center justify-between rounded-2xl lg2:flex-row">
            <div className="flex w-full flex-col items-center pt-0 lg2:w-[40%] xl:w-[60%] lg2:items-start gap-6 xl:gap-14 xl:pt-12">
              <div className="flex flex-col gap-6 w-full lg2:items-start items-center">
                <h1 className="h1 w-full text-center lg2:text-left text-white xl:w-[80%]">
                  Add a Powerful Tool to Your MSP Stack.
                </h1>

                {/* Mobile hero img */}
                <div className="block lg2:hidden w-full min-h-fit">
                  <img
                    src={PlatFormDashboardCTAImg}
                    alt="Dashboard Hero"
                    className="h-full w-full object-contain"
                  />
                </div>

                <p className="body-lg w-[75%] sm:w-[55%] !text-white lg2:w-[85%] xl:w-[70%] text-center lg2:text-left">
                  Take the next step in delivering cyber security excellence.
                  Join the CyberCert Partner Program today!
                </p>
              </div>

              <div className="flex flex-col gap-4 self-stretch overflow-hidden w-full lg2:w-[300px] xl:w-[450px] items-center lg2:items-start">
                <BenefitsSliderHero data={BENEFITS_DATA} />

                <ButtonWithRef
                  variant="partner"
                  onClick={() => scrollToElementSmoothly("become-partner", 500)}
                  className="!bg-blueGradient hover:!bg-blueGradientHover h-[40px] w-fit text-nowrap px-4"
                >
                  Get Started
                </ButtonWithRef>
              </div>
            </div>

            {/* 2xl to lg hero img */}
            <div
              className="hidden lg2:block absolute top-1/2 overflow-hidden -translate-y-1/2 w-1/2 
                            right-0
                            xl:right-0
                            2xl:right-[10px]"
            >
              <div className="relative w-full min-h-[600px]">
                <div
                  className="absolute bg-platform-hero overflow-hidden bg-no-repeat bg-contain w-full h-full
                              2xl:left-0 2xl:bg-[length:auto_555px] 2xl:bg-center
                              xl:left-[90px] xl:bg-[length:auto_555px] xl:bg-left xl:right-0 
                              lg2:bg-left lg2:bg-[length:auto_500px] lg2:left-[5px]"
                />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section
        id="become-partner"
        className="bg-blueGradient relative flex min-h-screen flex-grow items-center justify-center py-20 lg2:py-40"
      >
        <div className="absolute left-0 z-0 flex">
          <img src={ShieldBg} alt="CyberCert Shield" />
        </div>
        <div className="w-responsive z-10 flex-col gap-sm-c">
          <div className="gap-sm-c p-md-c flex w-full flex-col justify-between rounded-2xl bg-black backdrop-blur-sm bg-opacity-40 lg2:flex-row">
            <div className="gap-sm-c flex flex-col items-center justify-center lg:justify-between lg2:flex-row">
              <div className="gap-sm-c flex w-full flex-col justify-center lg2:w-1/2">
                <h2 className="h2 w-full text-white">
                  Join the CyberCert Partner Program!
                </h2>
                {/* <p className="body-lg w-full text-white">
                  We’re excited to introduce you to the CyberCert Partner
                  Program for Managed Service Providers (MSPs) — a simple and
                  effective way to guide your clients through their
                  cybersecurity certification journey whilst expanding your
                  service offerings in uplifting your clients' cybersecurity.
                </p> */}

                <div className="flex lg2:hidden w-full flex-col rounded-2xl shadow-[0_0px_50px_rgba(115,_169,_255,_0.6)] lg2:w-1/2">
                  <GetStartedCard handleGetStarted={handleGetStarted} />
                </div>
                <p className="body-lg w-full !text-white">
                  The CyberCert Cyber Partner Program is designed to empower
                  MSPs to deliver cybersecurity services to their clients. Once
                  you join, you'll have everything you need to help your clients
                  achieve their certification goals whilst expanding your
                  service offerings.
                </p>
              </div>

              <div className="lg2:flex hidden w-full flex-col rounded-2xl shadow-[0_0px_50px_rgba(115,_169,_255,_0.6)] lg2:w-1/2">
                <GetStartedCard handleGetStarted={handleGetStarted} />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="flex h-full flex-grow items-start justify-center overflow-hidden bg-white py-20 lg2:py-40">
        <div className="w-responsive gap-lg-c flex-col">
          <div className="flex w-full flex-col gap-4">
            <div className="gap-sm-c p-md-c flex w-full flex-col justify-between rounded-2xl bg-gradient-to-b from-gray-100 to-gray-200 lg2:flex-row xl:items-center lg2:items-start">
              <div className="gap-sm-c flex w-full flex-col justify-between">
                <h2 className="h2">What is CyberCert and SMB1001?</h2>

                <div className="lg2:hidden flex w-full h-full">
                  {/* <CyberCertToISO className="h-fit w-full outline-none" /> */}
                  <img
                    src="https://cybercert-public.s3.ap-southeast-2.amazonaws.com/cybercert-to-iso.svg"
                    alt="CyberCert to ISO"
                  />
                </div>

                <div className="gap-sm-c flex flex-col">
                  <p className="body-md">
                    CyberCert’s SMB1001 Certification is a game-changer for
                    MSPs, allowing you to streamline E8 and ISO/IEC27001
                    compliance journeys for your clients. With CyberCert, you’re
                    not just offering a service—you’re adding an essential,
                    high-demand tool to your MSP stack that boosts client trust
                    and expands your service offerings.
                  </p>
                  <Link
                    to={"https://www.cscau.com.au/smb1001"}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="flex flex-row items-center gap-2 text-[#48A6CD]"
                  >
                    <ButtonWithRef
                      variant="partner"
                      className="h-[40px] w-fit px-4"
                    >
                      Discover SMB1001
                    </ButtonWithRef>
                  </Link>
                </div>
              </div>
              <div className="hidden lg2:flex w-full h-full">
                {/* <CyberCertToISO className="h-fit w-full outline-none" /> */}
                <img
                  src="https://cybercert-public.s3.ap-southeast-2.amazonaws.com/cybercert-to-iso.svg"
                  alt="CyberCert to ISO"
                />
              </div>
            </div>
            <p className="body-xs text-gray w-full text-center">
              The ISO Logo and ISO/IEC 27001 name are trademarks of their
              respective owners.
            </p>
          </div>
        </div>
      </section>

      <section className="flex h-full flex-grow items-center justify-center bg-white">
        <div className="w-responsive justify-center">
          <SingleTestimonialPanel
            imgSrc={TESTIMONIALS_DATA["SEQIT"].imgSrc}
            quote={TESTIMONIALS_DATA["SEQIT"].quote}
            author={TESTIMONIALS_DATA["SEQIT"].author}
            company={TESTIMONIALS_DATA["SEQIT"].company}
            className={""}
          />
        </div>
      </section>

      <section className="flex min-h-screen flex-grow items-center justify-center overflow-hidden bg-white py-20 lg2:py-40">
        <div className="w-responsive gap-lg-c flex-col">
          <div className="gap-sm-c flex w-full flex-col">
            <div className="lg2:gap-8 xl:gap-md-c flex w-full flex-col justify-between rounded-2xl bg-black lg2:flex-row items-center">
              <div className="gap-sm-c flex w-full flex-col justify-between lg:w-1/2 p-md-c lg2:!pr-0">
                <h3 className="h3 text-white">
                  CyberCert is the official certification provider for SMB1001.
                </h3>

                <div className="flex lg2:hidden min-h-full w-full flex-col items-center lg:w-1/2">
                  <video
                    className="w-full xl:w-[85%]"
                    autoPlay
                    muted
                    loop
                    playsInline
                  >
                    <source src={LEVEL_ALL_ANIMATED} type="video/mp4" />
                    Your browser does not support the video tag.
                  </video>
                </div>

                <div className="gap-sm-c flex flex-col">
                  <p className="body-md !text-gray-300">
                    With five certification levels: Bronze, Silver, Gold,
                    Platinum, and Diamond your clients can choose the level that
                    fits their cybersecurity needs! Designed to help businesses
                    of all sizes achieve structured cyber resilience.
                  </p>
                  <Link
                    to={"https://www.cscau.com.au/smb1001"}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="flex flex-row items-center gap-2 text-[#48A6CD]"
                  >
                    <ButtonWithRef
                      variant="partner"
                      className="h-[40px] w-fit !bg-white !text-black hover:!bg-gray-200"
                    >
                      Discover SMB1001
                    </ButtonWithRef>
                  </Link>
                </div>
              </div>
              <div className="hidden lg2:flex min-h-full w-full flex-col items-center lg:w-1/2">
                <video
                  className="w-[85%] lg2:w-full xl:w-[85%]"
                  autoPlay
                  muted
                  loop
                  playsInline
                >
                  <source src={LEVEL_ALL_ANIMATED} type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
              </div>
            </div>

            <div className="flex flex-col gap-4">
              <div
                id="pricing"
                className="gap-sm-c flex flex-col items-center self-stretch"
              >
                <div
                  id="pricing"
                  className="gap-sm-c flex flex-col items-center self-stretch"
                >
                  {chunk(Object.keys(LEVELS), 3)?.map((chunk, i) => (
                    <div
                      className="flex flex-col gap-7 lg2:gap-5 xl:gap-8 self-stretch lg2:flex-row"
                      key={`Level-Chunk-${i}`}
                    >
                      {chunk?.map((level) => (
                        <LevelCard
                          key={LEVELS[level]}
                          level={level}
                          standard="SMB1001"
                          price={LEVEL_PRICES[level]}
                          details={LEVEL_DETAILS[level]}
                        />
                      ))}
                    </div>
                  ))}

                  {/* <div className="hidden items-center justify-end gap-4 self-stretch sm:flex">
                    <button
                      className="overflow-hidden rounded-full text-gray-400 enabled:hover:text-gray-500 disabled:opacity-50"
                      disabled={currentLevelSlide <= 0}
                      onClick={handlePrevLevelSlide}
                    >
                      <BsArrowLeftCircleFill className="size-10" />
                    </button>
                    <button
                      className="overflow-hidden rounded-full text-gray-400 enabled:hover:text-gray-500 disabled:opacity-50"
                      disabled={currentLevelSlide >= Object.keys(LEVELS).length - 1}
                      onClick={handleNextLevelSlide}
                    >
                      <BsArrowRightCircleFill className="size-10" />
                    </button>
                  </div> */}
                </div>
                <p className="body-xs text-gray w-full text-center lg:w-[65%]">
                  Verified certification subscriptions include external auditing
                  by one of CyberCert's Independent Verification Organizations
                  (IVO). Platinum and Diamond certifications will be available
                  from 1 January 2025.
                </p>
              </div>
            </div>
          </div>

          <LogoSlider
            title="Available on leading GRC platforms."
            orgs={GRC_DATA}
            className={"lg:!pb-0 xl:flex hidden"}
            xl_numPerSlide={[5]}
            lg_numPerSlide={[5]}
          />

          <div className="flex xl:hidden gap-md-c flex-col">
            <h2 className="h2 text-center">
              Available on leading GRC platforms.
            </h2>
            <div className="grid grid-cols-1 lg2:grid-cols-3 gap-sm-c p-5 lg2:p-0 mx-auto">
              {GRC_DATA?.map((org) => (
                <div className="h-[165px] w-[235px] items-center justify-center rounded-2xl bg-gray-100 hover:bg-blueGradientHover p-1.5 shadow-md hover:scale-110 sm:w-full">
                  <div className="bg-gray-100 p-6 w-full h-full flex items-center justify-center rounded-lg">
                    <img
                      src={org?.logo}
                      alt={`${org?.name} Logo`}
                      className="lg:w-32"
                    />
                  </div>
                </div>
              ))}
            </div>
          </div>

          <TestimonialSlider
            title="Available on leading GRC platforms."
            orgs={GRC_DATA}
            testimonies={TESTIMONIALS_DATA.collection}
          />
        </div>
      </section>

      {/* <section className="flex min-h-screen flex-grow items-center justify-center bg-black bg-waves-hero bg-cover bg-right py-40">
        <div className="w-responsive gap-md-c flex-col items-center">
          <div className="gap-sm-c flex w-full flex-col items-center justify-center lg:max-w-screen-md">
            <h2 className="h2 w-full text-center text-white lg:w-4/5">
              Why Join the CyberCert Partner Program?
            </h2>
            <p className="body-lg w-full text-center text-gray-300 md:w-[70%]">
              The CyberCert Cyber Assurance Program (CAP) is designed to empower
              Managed Service Providers (MSPs) to deliver cybersecurity
              certification services to their clients. Once you join, you'll
              have everything you need to help your clients reach their
              cybersecurity goals.
            </p>
            <ButtonWithRef
              variant="partner"
              className="!bg-blueGradient hover:!bg-blueGradientHover h-[40px] w-fit px-4 text-sm desktop:text-base"
              onClick={() => scrollToElementSmoothly("become-partner", 500)}
            >
              Get Started
            </ButtonWithRef>
          </div>
        </div>
      </section> */}

      <section className="flex min-h-full bg-gradient-to-r from-[#16336D] to-[#48A6CD] pt-20 lg2:pt-40">
        <div className="w-responsive gap-md-c flex-col items-center w-full">
          {/* <h1 className="h1 hidden xl:flex text-shadow-xl text-center text-white">
            Here’s what Partners get...
          </h1> */}
          <h2 className="h2 lg2:flex hidden text-shadow-xl text-center text-white">
            Here’s what Partners get...
          </h2>
          <h2 className="h2 flex lg2:hidden text-shadow-xl text-center text-white">
            What Partners get...
          </h2>

          <div className="flex flex-col items-end justify-end gap-16 self-stretch overflow-hidden w-full lg2:px-16 xl:px-64 2xl:pl-80">
            <div
              className="flex flex-col items-start gap-8 self-stretch transition-transform duration-500 ease-in-out lg2:flex-row"
              ref={benefitsRef}
              style={{
                transform: `translateX(max(calc(${currentBenefitSlide * -100}%), ${-(benefitsMaxSize - benefitsWidth)}px))`,
              }}
            >
              {BENEFITS_DATA?.map((item, index) => (
                <>
                  <BenefitCard
                    key={item.title}
                    icon={item.icon}
                    index={index}
                    title={item.title}
                    description={item.description}
                  />
                </>
              ))}
            </div>
          </div>

          <div className="hidden w-full items-center justify-center gap-4 self-stretch lg2:flex">
            <button
              className="overflow-hidden rounded-full text-gray-100 enabled:hover:text-gray-300 disabled:opacity-50"
              disabled={currentBenefitSlide <= 0}
              onClick={handlePrevBenefitSlide}
            >
              <BsArrowLeftCircleFill className="size-10" />
            </button>
            <button
              className="overflow-hidden rounded-full text-gray-100 enabled:hover:text-gray-300 disabled:opacity-50"
              disabled={
                currentBenefitSlide >=
                Math.floor(benefitsMaxSize / benefitsWidth)
              }
              onClick={handleNextBenefitSlide}
            >
              <BsArrowRightCircleFill className="size-10" />
            </button>
          </div>

          <div className="flex w-[100%] lg2:w-[60%] rounded-[100px] shadow-[0_10px_60px_rgba(115,_169,_255,_0.9)]">
            {/* <PlatFormDashboardHero className="h-fit w-full outline-none" /> */}
            <img src={PlatFormDashboardCutImg} alt="Dashboard Hero" />
          </div>
        </div>
      </section>

      <section className="flex h-full flex-grow items-center justify-center overflow-hidden bg-white py-20 lg2:py-40">
        <div className="w-responsive gap-lg-c flex-col">
          <LogoSlider
            title="Partnered with leading MSPs in the industry."
            orgs={LEADING_MSPS_DATA}
            className={"lg:!pb-0"}
            xl_numPerSlide={[4, 4, 4, 4, 5]}
            lg_numPerSlide={[3, 3, 3, 3, 3, 3, 3]}
          />

          <SingleTestimonialPanel
            imgSrc={TESTIMONIALS_DATA["huntress"].imgSrc}
            quote={TESTIMONIALS_DATA["huntress"].quote}
            author={TESTIMONIALS_DATA["huntress"].author}
            company={TESTIMONIALS_DATA["huntress"].company}
            className={"lg:!pt-0"}
          />
        </div>
      </section>

      <section className="bg-blueGradient flex min-h-screen flex-grow items-center justify-center py-20 lg2:py-40">
        <div className="w-responsive">
          <div className="gap-md-c flex w-full flex-col items-center">
            {/* <h1 className="h1">How Does It Work?</h1> */}

            <div className="gap-sm-c flex w-full flex-col">
              <div className="gap-sm-c flex w-full flex-col lg2:flex-row">
                <div className="flex w-full flex-col justify-center lg:w-1/2 gap-sm-c">
                  <h1 className="h1 text-white w-full lg2:w-[70%]">
                    How Does It Work?
                  </h1>
                  <h5 className="h5 w-full font-light text-white lg:w-[90%]">
                    Once you join the CyberCert Partner Program, you'll have
                    everything you need to help your clients reach their
                    cybersecurity certification goals.
                  </h5>
                </div>
                <div className="flex w-full items-center justify-center rounded-2xl lg:w-1/2">
                  {/* <video className="w-[50%]" autoPlay muted loop>
                    <source src={LEVEL_ALL_ANIMATED} type="video/mp4" />
                    Your browser does not support the video tag.
                  </video> */}
                  <img
                    src={ClientsNeeds}
                    alt="Business owners working"
                    className="h-full rounded-2xl object-cover"
                  />
                </div>
                {/* <div className="bg-waves-hero lg:w-1/2 p-sm-c gap-sm-c flex w-full flex-col items-center justify-center rounded-2xl bg-black bg-cover bg-right">
                  <video
                    src={DiamondAnimated}
                    autoPlay
                    loop
                    muted
                    className="w-full h-72"
                  />
                  <img
                    src={ShinyGold}
                    alt="CyberCert Gold Badge"
                    className="h-72"
                  />
                </div> */}
              </div>
              <div className="gap-sm-c flex w-full flex-col lg2:flex-row">
                {SERVICES_DATA.map((service) => (
                  <ServiceCard
                    key={service.title}
                    icon={service.icon}
                    title={service.title}
                    description={service.description}
                  />
                ))}
              </div>
            </div>

            <ButtonWithRef
              variant="partner"
              className="h-[40px] w-fit px-4"
              onClick={() => scrollToElementSmoothly("become-partner", 500)}
            >
              Get Started
            </ButtonWithRef>
          </div>
        </div>
      </section>

      <section className="flex min-h-full flex-grow items-center justify-center overflow-hidden bg-white py-20 lg2:py-40">
        <div className="w-responsive gap-lg-c flex-col">
          <SingleTestimonialPanel
            imgSrc={TESTIMONIALS_DATA["shadowIT"].imgSrc}
            quote={TESTIMONIALS_DATA["shadowIT"].quote}
            author={TESTIMONIALS_DATA["shadowIT"].author}
            company={TESTIMONIALS_DATA["shadowIT"].company}
          />
          <div className="gap-md-c p-md-c flex w-full flex-col rounded-2xl bg-gray-50 bg-waves-hero bg-cover bg-right">
            <div className="gap-sm-c flex h-fit w-full flex-col items-center lg2:flex-row">
              <div className="gap-sm-c flex h-full w-full flex-col justify-between lg:w-1/2">
                <h2 className="h2 w-full">
                  Why SMB1001 is right for your clients.
                </h2>

                <div className="flex lg2:hidden w-full flex-col rounded-2xl lg:w-1/2">
                  <img
                    src={ClientApproved}
                    alt="Business owners working"
                    className="rounded-2xl"
                  />
                </div>

                <div className="gap-sm-c flex flex-col">
                  <p className="body-lg w-full text-gray-900 lg2:w-[90%] xl:w-full">
                    A tiered, prescriptive, and affordable cybersecurity
                    standard designed specifically for SMBs.
                  </p>

                  <div className="flex w-full flex-col items-start justify-start gap-4 xl:flex-row xl:items-center">
                    <ButtonWithRef
                      variant="partner"
                      onClick={() => scrollToElementSmoothly("pricing", 500)}
                      className="!bg-blueGradient hover:!bg-blueGradientHover h-[40px] w-fit text-nowrap px-4 font-semibold"
                    >
                      Check the Pricing
                    </ButtonWithRef>

                    <Link
                      to={"https://cscau.com.au/smb1001"}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="flex flex-row items-center gap-2 body-sm font-medium !text-[#48A6CD] hover:!text-blue-600"
                    >
                      Learn more about SMB1001{" "}
                      <RiArrowRightUpLine className="h-4 w-4" />
                    </Link>
                  </div>
                </div>
              </div>
              <div className="hidden lg2:flex w-full flex-col rounded-2xl lg:w-1/2">
                <img
                  src={ClientApproved}
                  alt="Business owners working"
                  className="rounded-2xl"
                />
              </div>
            </div>

            <div className="gap-sm-c grid w-full grid-cols-1 lg:grid-cols-2">
              {FEATURES_DATA.map((feature) => (
                <FeatureSection
                  key={feature.title}
                  icon={feature.icon}
                  title={feature.title}
                  description={feature.description}
                />
              ))}
            </div>
          </div>

          <SingleTestimonialPanel
            imgSrc={TESTIMONIALS_DATA["catalyticIT"].imgSrc}
            quote={TESTIMONIALS_DATA["catalyticIT"].quote}
            author={TESTIMONIALS_DATA["catalyticIT"].author}
            company={TESTIMONIALS_DATA["catalyticIT"].company}
            className={"lg:!pt-0"}
          />
        </div>
      </section>

      <section className="flex min-h-full flex-grow items-center justify-center overflow-hidden bg-white pb-20 lg2:pb-40">
        <div className="w-responsive gap-lg-c flex-col">
          <div className="flex w-full flex-col gap-4">
            <div className="gap-5 lg2:gap-0 xl:gap-md-c flex w-full flex-col items-end justify-between rounded-2xl bg-gradient-to-b from-gray-100 to-gray-200 lg2:flex-row">
              <div className="gap-sm-c p-md-c flex w-full flex-col lg2:w-[50%] lg2:!pr-0">
                <h1 className="h2 text-center lg2:text-left w-full xl:h1 lg2:w-[65%] xl:w-[90%]">
                  Industry built & maintained.
                </h1>

                <div className="flex lg2:hidden w-full flex-col xl:items-center p-0 lg:w-1/2 lg2:items-start border-b-4 border-gray-300 lg2:border-0">
                  <img
                    src={SMB1001Img}
                    className="object-contain h-[200px] lg2:h-[270px] xl:h-[420px] lg2:pt-0 xl:pt-10"
                    alt="SMB1001:2025"
                  />
                </div>

                <h4 className="h4 text-center lg2:text-left w-full lg:w-[85%]">
                  By leading experts in public and private cybersecurity
                  sectors.
                </h4>

                <div className="flex w-full items-center justify-center lg2:justify-start gap-4 xl:flex-row flex-col-reverse lg2:items-start  xl:items-center">
                  <Link
                    to={"https://www.cscau.com.au/smb1001"}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="flex flex-row items-center gap-2 text-[#48A6CD] text-nowrap"
                  >
                    <ButtonWithRef
                      variant="partner"
                      className="h-[40px] w-fit px-4"
                    >
                      Discover SMB1001
                    </ButtonWithRef>
                  </Link>

                  <Link
                    to={"https://cscau.com.au/#standard-purchase-2025-cta"}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="flex flex-row pl-0 justify-center lg2:justify-start lg2:items-center w-full gap-2 font-semibold text-gray-900 hover:text-blue-600 body-md lg2:pl-2"
                  >
                    Purchase SMB1001 <RiArrowRightUpLine className="h-5 w-5" />
                  </Link>
                </div>
              </div>
              <div className="hidden lg2:flex w-full flex-col xl:items-center p-0 lg2:w-1/2 lg2:items-start">
                <img
                  src={SMB1001Img}
                  className="object-contain h-[200px] lg2:h-[270px] xl:h-[420px] lg2:pt-0 xl:pt-10"
                  alt="SMB1001:2025"
                />
              </div>
            </div>
            <p className="body-xs text-gray w-full text-center text-xs">
              The CSCAU Logo is a trademark of its respective owner.
            </p>
          </div>
        </div>
      </section>
      <section className="flex min-h-full flex-grow items-center justify-center bg-black bg-waves-hero bg-cover bg-right py-20 lg2:py-40">
        <div className="w-responsive">
          <div className="gap-md-c flex w-full flex-col items-center justify-center">
            <h2 className="h2 text-center text-white">Ready to Get Started?</h2>

            {/* <TabletSlider images={TABLET_IMAGES} /> */}

            <div className="flex w-[80%] rounded-[100px] shadow-[0_5px_60px_rgba(115,_169,_255,_0.9)]">
              <img
                src={PlatFormDashboardCTAImg}
                alt="Dashboard Hero"
                className="h-full w-full object-contain"
              />
            </div>

            <div className="gap-sm-c flex w-full flex-col items-center justify-center">
              <h3 className="h3 w-full text-center text-white">
                Joining is easy!
              </h3>
              <p className="body-lg flex flex-row items-center justify-center text-center !text-gray-300 w-full sm:w-[55%] xl:w-[50%]">
                Take the next step in delivering cybersecurity excellence. Join
                the CyberCert Partner Program today!
              </p>
              <ButtonWithRef
                variant="partner"
                className="!bg-blueGradient hover:!bg-blueGradientHover h-[40px] w-fit px-4"
                onClick={() => scrollToElementSmoothly("become-partner", 1000)}
              >
                Get Started
              </ButtonWithRef>
            </div>
          </div>
        </div>
      </section>
      <section className="flex min-h-full xl:h-screen flex-grow items-center justify-center overflow-hidden bg-white py-20 lg2:py-40">
        <div className="w-responsive gap-lg-c flex-col">
          <div className="flex w-full flex-col gap-4">
            <div className="gap-sm-c xl:gap-md-c p-md-c flex w-full flex-col xl:items-center lg2:items-start justify-between rounded-2xl bg-gradient-to-b from-gray-100 to-gray-200 lg2:flex-row">
              <div className="gap-sm-c flex w-full flex-col justify-between">
                <h3 className="h3 flex w-full flex-col gap-0">
                  <span className="text-[#48A6CD]">Whitepaper</span>
                  Cyber Assurance Program (CAP) for MSPs
                </h3>

                <div className="gap-sm-c flex lg2:hidden w-full flex-col">
                  <img
                    src={MSPWithClient}
                    alt="Business owners working"
                    className="rounded-2xl"
                  />
                </div>

                <div className="gap-sm-c flex flex-col">
                  <p className="body-md">
                    Learn about CyberCert's Cyber Assurance Program (CAP) and
                    how it provides MSPs with a dynamic, tiered cybersecurity
                    certification framework through SMB1001. A guide to
                    delivering certifiable cyber security solutions for managed
                    service providers (MSPs).
                  </p>
                  <ButtonWithRef
                    variant="partner"
                    className="!bg-blueGradient hover:!bg-blueGradientHover h-[40px] w-fit px-4 text-sm desktop:text-base"
                    onClick={downloadCAP}
                  >
                    Download
                  </ButtonWithRef>
                </div>
              </div>
              <div className="hidden gap-sm-c lg2:flex w-full flex-col">
                <img
                  src={MSPWithClient}
                  alt="Business owners working"
                  className="rounded-2xl"
                />
              </div>
            </div>
            {/* <p className="body-xs text-gray w-full text-center">
              The ISO Logo and ISO/IEC 27001 name are trademarks of their
              respective owners.
            </p> */}
          </div>
        </div>
      </section>
      <section className="flex w-full flex-col items-center bg-gradient-to-r from-[#16336D] to-[#48A6CD]">
        <div className="flex w-full 2xl:px-0 px-5 lg2:px-[4%] 2xl:max-w-[1300px] mx-auto">
          <div className="gap-sm-c flex w-full flex-col items-center justify-between py-8 lg2:flex-row">
            <h3 className="h3 text-white text-center lg2:text-left w-full">
              Join the CyberCert Partner Program today!
            </h3>
            <ButtonWithRef
              variant="partner"
              className="h-[40px] w-fit px-4 text-nowrap"
              onClick={() => scrollToElementSmoothly("become-partner", 1000)}
            >
              Get Started
            </ButtonWithRef>
          </div>
        </div>
      </section>
    </>
  );
}

const BENEFITS_DATA = [
  {
    icon: AiOutlineDollarCircle,
    title: "No Cost to Join",
    description: "Becoming a CyberCert partner is free.",
  },
  {
    icon: BiTrendingUp,
    title: "Business Growth Opportunities",
    description:
      "The SMB1001 Certification empowers MSPs to streamline clients' Essential 8 and ISO/IEC27001 compliance journeys while delivering tailored security services that generate revenue.",
  },
  {
    icon: RiBankFill,
    title: "Cyber Insurance",
    description:
      "Certifications that help your clients obtain cyber insurance, and help you offer additional risk management solutions.",
  },
  {
    icon: CgScreen,
    title: "Dashboard & Tools",
    description:
      "Manage your clients’ cybersecurity certification journey through our intuitive dashboard, including tracking their progress and assessing their certification needs.",
  },
  {
    icon: RiPriceTag3Line,
    title: "Discounted Subscriptions",
    description:
      "Enjoy access to exclusive discounts on certifications you can use yourself or pass on to your clients.",
  },
  {
    icon: TbShieldSearch,
    title: "Risk Reduction",
    description:
      "By ensuring client compliance, MSPs also reduce their own liability and minimize the risk of cyber incidents, lowering the potential for legal exposure.",
  },
  {
    icon: BsUiChecksGrid,
    title: "Independent Standards",
    description:
      "SMB1001 certification provides credibility through independent, structured requirements—no need to develop your own criteria.",
  },
  {
    icon: AiOutlineVerticalAlignMiddle,
    title: "Align with Essential Eight",
    description:
      "Use SMB1001 as a practical roadmap or in conjunction with recognized frameworks like the Essential Eight.",
  },
];

const LEADING_MSPS_DATA = [
  { logo: PAditsLogo, name: "Adits", link: "https://www.adits.com.au" },
  { logo: PApolloLogo, name: "Apollo", link: "https://www.apollosecure.com" },
  { logo: PBitsLogo, name: "Bits", link: "https://www.bitsgroup.com.au" },
  {
    logo: PBizGuardLogo,
    name: "Biz Guard",
    link: "http://www.bizguard.com.au",
  },
  { logo: PBizItLogo, name: "Biz IT", link: "https://www.biz-it.com.au" },
  {
    logo: PCatalyticItLogo,
    name: "Catalytic IT",
    link: "https://citwa.com.au",
  },
  {
    logo: PConcordItLogo,
    name: "Concord IT",
    link: "https://concordit.com.au",
  },
  {
    logo: PCorCyberLogo,
    name: "Cor Cyber",
    link: "https://www.corcyber.com.au",
  },
  {
    logo: PCyberGuruLogo,
    name: "Cyber Guru",
    link: "https://www.cyberguru.com.au",
  },
  {
    logo: PEResourcesLogo,
    name: "E-Resources",
    link: "https://eresources.com.au",
  },
  { logo: PGeeks2uLogo, name: "Geeks2u", link: "https://www.geeks2u.com.au" },
  {
    logo: PHolocronCyberLogo,
    name: "Holocron Cyber",
    link: "http://www.holocroncyber.com.au",
  },
  // { logo: PHuntressLogo, name: "Huntress", link: "https://www.huntress.com" },
  { logo: PIsparexLogo, name: "Isparex", link: "https://www.isparex.com.au" },
  // {
  //   logo: PPMcGrathNicolLogo,
  //   name: "McGrath Nicol",
  //   link: "https://www.mcgrathnicol.com",
  // },
  {
    logo: PMercuryItLogo,
    name: "Mercury IT",
    link: "https://www.mercuryit.com.au",
  },
  {
    logo: PMustardSeedItLogo,
    name: "Mustard Seed IT",
    link: "https://mustardseedit.com",
  },
  { logo: PReddLogo, name: "REDD", link: "https://redd.com.au" },
  { logo: PSekuroLogo, name: "Sekuro", link: "https://sekuro.io" },
  { logo: PSentrianLogo, name: "Sentrian", link: "http://www.sentrian.com.au" },
  {
    logo: PSeqItServicesLogo,
    name: "SEQ IT Services",
    link: "http://www.seqit.com.au",
  },
  {
    logo: PTarianCyberLogo,
    name: "Tarian Cyber",
    link: "https://tarian.com.au",
  },
  { logo: PTechPathLogo, name: "Tech Path", link: "https://techpath.com.au" },
];

const CSCAU_LOGOS = [
  CSCAUAusCertLogo,
  CSCAUACSLogo,
  CSCAUAdhaLogo,
  CSCAUAisaLogo,
  CSCAUAsdLogo,
  CSCAUBdoLogo,
  CSCAUCosboaLogo,
  CSCAUCybercxLogo,
  CSCAUDeloitteLogo,
  CSCAUGovsaLogo,
  CSCAUInsurancecouncilLogo,
  CSCAUMcGrathNicolLogo,
  CSCAUPacsonLogo,
  CSCAUSekuroLogo,
  CSCAUWillyamaCyberLogo,
];

const FEATURES_DATA = [
  {
    icon: <RiExchangeDollarLine className="h-8 w-8 text-[#1D4492]" />,
    title: "Affordable & Practical",
    description:
      "Designed for SMBs, it’s a cost-effective solution that aligns with government expectations and helps SMBs stay ahead of cybersecurity risks.",
  },
  {
    icon: <RiCheckboxMultipleLine className="h-8 w-8 text-[#1D4492]" />,
    title: "Fills the Cyber Standards Gap",
    description:
      "Provides SMBs with a certification option that articulates their cybersecurity maturity.",
  },
  {
    icon: <GiCheckedShield className="h-8 w-8 text-[#1D4492]" />,
    title: "Qualify for Cyber Insurance",
    description:
      "Help your SMB clients qualify for affordable, fit-for-purpose cyber insurance policies.",
  },
  {
    icon: <RiShieldUserLine className="h-8 w-8 text-[#1D4492]" />,
    title: "Align with Privacy Obligations",
    description:
      "SMB1001 ensures you and your clients meet Privacy Act requirements for data protection.",
  },
  {
    icon: <TbShieldCog className="h-8 w-8 text-[#1D4492]" />,
    title: "Enhance Data Security",
    description:
      "Help your clients meet their Australian Privacy Principles (APPs) and reduce data breach risks by ensuring compliance with APP 11.",
  },
  {
    icon: <VscOpenPreview className="h-8 w-8 text-[#1D4492]" />,
    title: "Lack of SMB Cyber Standards",
    description:
      "Global frameworks like ISO/IEC 27001 are tailored for large enterprises, leaving SMBs without suitable certification options.",
  },
];

const GRC_DATA = [
  { logo: GRCSixClicksLogo, name: "6Clicks" },
  {
    logo: GRCAssuredlyLogo,
    name: "Assuredly",
  },
  {
    logo: GRCCyberAwareLogo,
    name: "Cyber Aware",
  },
  { logo: GRCCynchLogo, name: "Cynch" },
  { logo: GRCMyCISOLogo, name: "MyCISO" },
];

const SERVICES_DATA = [
  {
    icon: <HiViewGridAdd className="h-16 w-16 text-[#1D4492]" />,
    title: "Assess",
    description:
      "Conduct a certification GAP assessment to determine which SMB1001 certification level your clients can achieve immediately and identify the steps needed to certify at higher levels.",
  },
  {
    icon: <CgScreen className="h-16 w-16 text-[#1D4492]" />,
    title: "Manage",
    description:
      "Leverage the Partner Dashboard to manage your clients compliance journey, driving recurring sales and expanding your service offerings beyond certification.",
  },
  {
    icon: <BsFillBookmarksFill className="h-16 w-16 text-[#1D4492]" />,
    title: "Service",
    description:
      "Provide service offerings to help clients implement the necessary requirements to achieve their desired SMB1001 certification level.",
  },
];

const FeatureSection = ({ icon, title, description }) => (
  <div className="flex w-full flex-col gap-4 lg:w-3/4">
    {icon}
    <h4 className="h4 text-gray-900">{title}</h4>
    <p className="body-md text-gray">{description}</p>
  </div>
);

const ServiceCard = ({ icon, title, description }) => (
  <div className="p-sm-c flex w-full flex-col gap-6 rounded-2xl bg-gray-50 lg:w-1/3">
    {icon}
    <h3 className="h3 text-[#1D4492]">{title}</h3>
    <p className="body-md text-gray">{description}</p>
  </div>
);

const TESTIMONIALS_DATA = {
  huntress: {
    imgSrc: THuntressLogo,
    quote: `Historically it has been a challenge for SMBs to uplift their cybersecurity posture in a clear, affordable and effective way. CSCAU's SMB1001 solves this challenge by providing a standard which enables SMBs to baseline their cybersecurity posture against a clear and attainable set of controls, with a pathway to continuous improvement as they move through Bronze, Silver, Gold and beyond. MSPs now have a mechanism to prove the uplift delivered to their clients, and take them on a cybersecurity improvement journey.`,
    author: "Reece Appleton",
    company: "Huntress",
  },
  shadowIT: {
    imgSrc: TShadowITLogo,
    quote: `At Shadow IT, we leverage the SMB1001 standard to small and medium-sized businesses (SMBs) due to its attainable, scalable, and robust cybersecurity controls. These controls facilitate the development of an internal framework for continuous improvement in cybersecurity maturity. Moreover, the SMB1001 is structured to help address organisational change resistance, provide business justification for increased IT expenditure, and establish a clear pathway towards more comprehensive cybersecurity frameworks, such as the Australian Signals Directorate's (ASD) Essential Eight program.`,
    author: "Ben Dean",
    company: "Shadow IT",
  },
  catalyticIT: {
    imgSrc: TCatalyticITLogo,
    quote: `The SMB1001 standard is a practical standard that ensures an organisation can showcase their progress towards a more secure organisation. We’ve found the levels to be simple to follow, rewarding for organisations as they can easily follow their progression, and yet provide practical security uplift. We’re embedding the levels into strategic planning for our customers and we hope to deliver real-world benefits for them in doing so.`,
    author: "Michael Lester",
    company: "Catalytic IT",
  },
  SEQIT: {
    imgSrc: TSEQITServicesLogo,
    quote: `SMB1001 is our secret weapon! The annually updated standard keeps our IT security measures current, giving our clients peace of mind with clear and actionable guidance and also keeps us in demand! Not only this, it offers a huge competitive edge and ensures our clients don’t lag behind.`,
    author: "Michael Gibby",
    company: "SEQ IT",
  },
  collection: [
    {
      author: "Fiona Long",
      logo: GRCAssuredlyLogo,
      name: "Assuredly",
      quote:
        "Recognizing the need for a more accessible standard, Assuredly has embraced SMB1001, a new security standard/framework developed by CSCAU. Unlike its predecessors, SMB1001 offers a pragmatic and tiered approach to security controls, enabling small businesses to achieve Tier 1 compliance swiftly and enhance their security measures over time.\nWhile we know the Essential Eight are created to address some of the most critical security vulnerabilities seen by the ASD, the SMB1001 Standard gives an entry point for ALL businesses, regardless of resources or expertise to start uplifting their security today.",
    },
    {
      author: "Daniel	Johns",
      logo: GRCMyCISOLogo,
      name: "MyCISO",
      quote:
        "Finally, an Australian government approved cyber security framework that fits businesses of all sizes. SMB1001 makes cyber resilience achievable, demonstratable, and certifiable!",
    },
  ],
};

const TABLET_IMAGES = [
  {
    src: PlatformDashboardSlide7,
    alt: "Dashboard Platform Slide 7",
  },
  {
    src: PlatformDashboardSlide6,
    alt: "Dashboard Platform Slide 6",
  },
  {
    src: PlatformDashboardSlide5,
    alt: "Dashboard Platform Slide 5",
  },
  {
    src: PlatformDashboardSlide4,
    alt: "Dashboard Platform Slide 4",
  },
  {
    src: PlatformDashboardSlide3,
    alt: "Dashboard Platform Slide 3",
  },
  {
    src: PlatformDashboardSlide2,
    alt: "Dashboard Platform Slide 2",
  },
  {
    src: PlatformDashboardSlide1,
    alt: "Dashboard Platform Slide 1",
  },
];

const SingleTestimonialPanel = ({
  imgSrc,
  quote,
  author,
  company,
  className,
}) => {
  return (
    <div
      className={`gap-md-c lg2:p-0 xl:p-lg-c flex lg2:w-[90%] xl:w-full flex-col items-center lg2:flex-row pt-4 ${className}`}
    >
      <div className="flex w-full flex-row items-center justify-center lg2:w-1/3">
        <img
          src={imgSrc}
          alt={`${company} Logo`}
          className="h-auto w-[150px] rounded-full object-contain outline outline-4 outline-offset-8 outline-blue-400 xl:h-[180px] xl:w-[180px]"
        />
      </div>
      <div className="gap-sm-c flex w-full flex-col lg2:w-2/3 items-center lg2:items-start">
        <HiChatBubbleLeft className="h-8 w-8 text-gray-400" />
        <p className="body-lg text-gray-900 text-center lg2:text-left">
          {quote}
        </p>
        <p className="body-lg">
          <span className="span pr-2 font-semibold text-gray-900">
            {author}
          </span>{" "}
          /<span className="span px-2">{company}</span>
        </p>
      </div>
    </div>
  );
};
