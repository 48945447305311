import AditsLogoPng from "./adits.png";
import ApolloLogoPng from "./apollo.png";
import BitsLogoPng from "./bits.png";
import BizGuardPng from "./biz-guard.png";
import BizItPng from "./biz-it.png";
import CatalyticItPng from "./catalytic-it.png";
import ConcordItPng from "./concord-it.png";
import CorCyberPng from "./cor-cyber.png";
import CyberGuruPng from "./cyber-guru.png";
import EResourcesPng from "./e-resources.png";
import Geeks2uPng from "./geeks2u.png";
import HolocronCyberPng from "./holocron-cyber.png";
import HuntressPng from "./huntress.png";
import IsparexPng from "./isparex.png";
import McGrathNicolPng from "./mcgrath-nicol.png";
import MercuryItPng from "./mercury-it.png";
import MustardSeedItPng from "./mustard-seed-it.png";
import ReddPng from "./redd.png";
import SekuroPng from "./sekuro.png";
import SentrianPng from "./sentrian.png";
import SeqItServicesPng from "./seq-it-services.png";
import TarianCyberPng from "./tarian-cyber.png";
import TechPathPng from "./tech-path.png";

export const PAditsLogo = AditsLogoPng;
export const PApolloLogo = ApolloLogoPng;
export const PBitsLogo = BitsLogoPng;
export const PBizGuardLogo = BizGuardPng;
export const PBizItLogo = BizItPng;
export const PCatalyticItLogo = CatalyticItPng;
export const PConcordItLogo = ConcordItPng;
export const PCorCyberLogo = CorCyberPng;
export const PCyberGuruLogo = CyberGuruPng;
export const PEResourcesLogo = EResourcesPng;
export const PGeeks2uLogo = Geeks2uPng;
export const PHolocronCyberLogo = HolocronCyberPng;
export const PHuntressLogo = HuntressPng;
export const PIsparexLogo = IsparexPng;
export const PPMcGrathNicolLogo = McGrathNicolPng;
export const PMercuryItLogo = MercuryItPng;
export const PMustardSeedItLogo = MustardSeedItPng;
export const PReddLogo = ReddPng;
export const PSekuroLogo = SekuroPng;
export const PSentrianLogo = SentrianPng;
export const PSeqItServicesLogo = SeqItServicesPng;
export const PTarianCyberLogo = TarianCyberPng;
export const PTechPathLogo = TechPathPng;
