import { useEffect, useRef, useState } from "react";

import { useWindowSize } from "contexts";
import { HiChatBubbleLeft } from "react-icons/hi2";
import { chunk } from "services";

export function TestimonialSlider({ orgs = [], testimonies = [] }) {
  const [currentOrgSlide, setCurrentOrgSlide] = useState(0);
  const [currentTestimonialSlide, setCurrentTestimonialSlide] = useState(0);
  const [touchStartX, setTouchStartX] = useState(0);
  const [touchDiffX, setTouchDiffX] = useState(0);

  const { wWidth, wHeight } = useWindowSize();

  const intervalOrgRef = useRef(null);

  /*********************** ORG SLIDE HANDLING ***********************/
  // const handleChangeOrgSlide = (index) => {
  //   setCurrentOrgSlide(index);
  //   resetInterval();
  // };
  // const handleNextOrgSlide = () => {
  //   setCurrentOrgSlide((prev) =>
  //     Math.min(prev + 1, orgChunks.length - 1)
  //   );
  // };
  // const handlePrevOrgSlide = () => {
  //   setCurrentOrgSlide((prev) => Math.max(prev - 1, 0));
  // };

  /*********************** TESTIMONIAL SLIDE HANDLING ***********************/
  const handleChangeTestimonialSlide = (index) => {
    setCurrentTestimonialSlide(index);
  };
  const handleNextTestimonialSlide = () => {
    setCurrentTestimonialSlide((prev) =>
      Math.min(prev + 1, testimonies.length - 1)
    );
  };
  const handlePrevTestimonialSlide = () => {
    setCurrentTestimonialSlide((prev) => Math.max(prev - 1, 0));
  };

  /***************************** SWIPE HANDLING *****************************/
  const handleTouchStart = (event) => {
    setTouchStartX(event.touches[0].clientX);
  };

  const handleTouchMove = (event) => {
    setTouchDiffX(event.touches[0].clientX - touchStartX);
  };

  const handleTouchEnd = () => {
    if (touchDiffX > 50) {
      handlePrevTestimonialSlide();
    } else if (touchDiffX < -50) {
      handleNextTestimonialSlide();
    }

    setTouchStartX(0);
    setTouchDiffX(0);
  };

  let numPerChunk;
  if (wWidth >= 1536) {
    numPerChunk = 5;
  } else if (wWidth >= 1280) {
    numPerChunk = 5;
  } else if (wWidth >= 1024) {
    numPerChunk = 3;
  } else if (wWidth >= 640) {
    numPerChunk = 2;
  } else {
    numPerChunk = 5;
  }

  const orgChunks = chunk(orgs, numPerChunk).map((chunk) => {
    const paddingNeeded = numPerChunk - chunk.length;
    const fullPads = Math.floor(paddingNeeded / 2);
    const halfPads = paddingNeeded % 2;

    return [
      ...Array(fullPads).fill("full-pad"),
      ...Array(halfPads).fill("half-pad"),
      ...chunk,
      ...Array(halfPads).fill("half-pad"),
      ...Array(fullPads).fill("full-pad"),
    ];
  });

  const resetInterval = () => {
    if (intervalOrgRef.current) {
      clearInterval(intervalOrgRef.current);
    }
    intervalOrgRef.current = setInterval(() => {
      // setCurrentOrgSlide((prev) => (prev + 1) % orgChunks.length);
    }, 5000);
  };

  useEffect(() => {
    resetInterval();

    if (orgChunks.length <= 1) {
      setCurrentOrgSlide(0);
    }

    return () => clearInterval(intervalOrgRef.current);
  }, [orgChunks.length]);

  return (
    <div className="gap-md-c flex flex-col items-center justify-center self-stretch">
      {/* <div className="flex gap-6 self-stretch">
        {orgChunks.map((chunk, i) => (
          <div
            className={`flex w-full flex-shrink-0 flex-col items-center justify-center gap-6 self-stretch py-2 transition-all duration-[2500ms] ease-in-out sm:flex-row ${currentOrgSlide === i ? "opacity-100" : "opacity-0"}`}
            key={`Org-Chunk-${i}`}
            style={{
              transform: `translateX(calc(${currentOrgSlide * -100}% + ${currentOrgSlide * -24}px))`,
            }}
          >
            {chunk?.map((org, j) =>
              org === "full-pad" ? (
                <div key={j} className="hidden flex-1 sm:block" />
              ) : org === "half-pad" ? (
                <div key={j} className="hidden flex-[0.5] sm:block" />
              ) : currentOrgSlide === i ? (
                // <Link
                //   key={org?.name}
                //   className="flex-1"
                //   rel="noopener noreferrer"
                //   target="_blank"
                //   to={org?.link}
                // >
                <div className="flex h-[165px] w-[235px] items-center justify-center rounded-2xl bg-gray-100 hover:bg-blueGradientHover p-1.5 shadow-md hover:scale-110 sm:w-full">
                  <div className="bg-gray-100 p-6 w-full h-full flex flex-col items-center justify-center rounded-lg">
                    <img
                      src={org?.logo}
                      alt={`${org?.name} Logo`}
                      className="lg:w-40"
                    />
                  </div>
                </div>
              ) : (
                // </Link>
                <div key={org?.name} className="flex-1">
                  <div className="flex h-[165px] w-[235px] items-center justify-center rounded-2xl bg-gray-100 hover:bg-blueGradientHover p-1.5 shadow-md hover:scale-110 sm:w-full">
                    <div className="bg-gray-100 p-6 w-full h-full flex flex-col items-center justify-center rounded-lg">
                      <img
                        src={org?.logo}
                        alt={`${org?.name} Logo`}
                        className="lg:w-32"
                      />
                    </div>
                  </div>
                </div>
              )
            )}
          </div>
        ))}
      </div> */}

      {/* {orgChunks?.length > 1 && (
        <div className="flex items-center justify-center gap-4 self-stretch">
          {orgChunks?.map((_, i) => (
            <button
              key={`Btn-${i}`}
              className={`size-4 rounded-full enabled:hover:bg-gray-300 ${currentOrgSlide === i ? "bg-gray-400" : "bg-gray-100"}`}
              disabled={currentOrgSlide === i}
              onClick={() => handleChangeOrgSlide(i)}
            />
          ))}
        </div>
      )} */}

      <div
        className="flex self-stretch overflow-hidden"
        onTouchEnd={handleTouchEnd}
        onTouchMove={handleTouchMove}
        onTouchStart={handleTouchStart}
      >
        {testimonies?.map((item, index) => (
          <div
            key={index}
            className="gap-md-c flex w-full flex-shrink-0 flex-col justify-center transition-transform duration-1000 ease-in-out lg:flex-row lg:items-center"
            style={{
              transform: `translateX(-${currentTestimonialSlide * 100}%)`,
            }}
          >
            {/* <div className="h-83.5 lg:flex-1 flex items-center justify-center gap-2.5 rounded-2xl bg-gray-100">
              <img
                src={item?.logo}
                alt={`${item?.name} Logo`}
                className="lg:w-64 w-40"
              />
            </div> */}

            <div className="flex w-full flex-col items-center gap-12 lg:w-2/3">
              {/* <FaQuoteLeft className="h-8 w-8 text-gray-400" /> */}
              <HiChatBubbleLeft className="h-8 w-8 text-gray-400" />

              <p className="body-lg text-center text-gray-900">{item?.quote}</p>

              <div className="flex items-center gap-2.5">
                <p className="body-lg">
                  <span className="span pr-2 font-semibold text-gray-900">
                    {item?.author}
                  </span>{" "}
                  /<span className="span px-2 text-gray">{item?.name}</span>
                </p>
              </div>
            </div>
          </div>
        ))}
      </div>

      <div className="gap-sm-c flex h-fit w-full flex-col">
        <div className="flex items-center justify-center gap-4 self-stretch">
          {testimonies?.map((_, i) => (
            <button
              key={i}
              className={`size-4 rounded-full enabled:hover:bg-gray-300 ${currentTestimonialSlide === i ? "bg-gray-400" : "bg-gray-100"}`}
              disabled={currentTestimonialSlide === i}
              onClick={() => handleChangeTestimonialSlide(i)}
            />
          ))}
        </div>
      </div>
    </div>
  );
}
